/* istanbul ignore file */
import React, { useEffect } from 'react';
import { visuallyHidden } from '@noths/polaris-client-styles';

import { Divider } from 'src/components/atoms/Divider/Divider';
import { ProgressBar } from 'src/components/atoms/ProgressBar/ProgressBar';
import { SkeletonProductList } from 'src/components/organisms/ProductList/components/SkeletonProductList';
import { ProductListContainer } from 'src/components/organisms/ProductList/containers/ProductListContainer';
import { PAGINATION_WRAPPER_ID } from 'src/components/organisms/ProductPages/constants/elementIds';
import type { ProductPagesProps } from 'src/components/organisms/ProductPages/types';
import { PRODUCT_CONTAINER_WRAPPER, PRODUCTS_GRID_WRAPPER } from 'src/constants/elementIds';
import type { UpdateHistoryPayload } from 'src/redux/products/thunks/updatePageHistory';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import { pageHistory } from 'src/services/pageHistory';
import { getPageNumbers, getProductCountUpToEndOfPage } from 'src/utils/product';
import {
  getProductCardNodeByCode,
  scrollProductCardIntoView,
} from 'src/utils/scrollProductCardIntoView';
import { PaginationButtonLink } from './PaginationButtonLink';
import * as styles from './ProductPages.styles';

type LastViewedProduct = Pick<UpdateHistoryPayload, 'productCode'>;

/**
 * Currently responsible for rendering:
 *  - ProductLists
 *  - load more/previous buttons.
 *  - progress bars/pagination messaging
 *  - determining loading states of pages
 *
 * Should be responsible for rendering everything listed above plus:
 *  - expanded search messaging
 * @component
 */
export const ProductPages = ({
  favouriteProductCodes,
  isLoading,
  loadMoreURL,
  loadPreviousURL,
  onLoadMore,
  onLoadPrevious,
  pageLoading,
  productsByPage,
  totalPages,
  totalProducts,
}: ProductPagesProps) => {
  const pageNumbers = getPageNumbers(productsByPage);
  const lastPageDisplayed = pageNumbers.slice(-1)[0];
  const loadingPreviousPage = pageLoading && pageLoading < pageNumbers[0];
  const loadingOnlyPage = pageLoading && pageNumbers.length === 0;
  const loadingNextPage = pageLoading && pageLoading > lastPageDisplayed;

  useEffect(() => {
    const product = pageHistory!.get<LastViewedProduct>('lastViewedProduct');

    if (product) {
      scrollProductCardIntoView(getProductCardNodeByCode(product.productCode));
    }
  }, []);

  useEffect(() => {
    if (loadingOnlyPage) {
      const productContainerOffsetTop =
        document.getElementById(PRODUCT_CONTAINER_WRAPPER)?.offsetTop;

      window.scrollTo({ top: productContainerOffsetTop, behavior: 'auto' });
    }
  }, [loadingOnlyPage]);

  const splitProductsByIsExpandedSearch = (productsForThisPage: BrowseDataAPIProduct[]) => {
    return productsForThisPage.reduce(
      (result, product) => {
        result[!product.isExpandedSearch ? 0 : 1].push(product);
        return result;
      },
      [[], []] as [BrowseDataAPIProduct[], BrowseDataAPIProduct[]],
    );
  };

  return (
    <div css={styles.wrapper} id={PRODUCTS_GRID_WRAPPER}>
      <h2 css={visuallyHidden}>Products</h2>
      {!isLoading && pageNumbers[0] > 1 && (
        <PaginationButtonLink
          href={loadPreviousURL}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onLoadPrevious({ page: pageNumbers[0] });
          }}
        >
          Load previous<span css={visuallyHidden}> products</span>
        </PaginationButtonLink>
      )}
      {loadingPreviousPage && <SkeletonProductList />}
      {loadingOnlyPage ? (
        <SkeletonProductList />
      ) : (
        pageNumbers.map((page) => {
          const productsForThisPage = productsByPage[page];
          const [products, expandedProducts] = splitProductsByIsExpandedSearch(productsForThisPage);
          const renderExpandedResultsMessage = !!expandedProducts?.length && page === 1;
          // eslint-disable-next-line no-extra-boolean-cast
          const expandedResultsMessage = !!products?.length ? (
            <Divider text="Similar items based on your search" />
          ) : (
            <h2 css={styles.expandedResultsMessage}>
              We couldn’t find an exact match – but you might like these…
            </h2>
          );
          const progressCount = getProductCountUpToEndOfPage(productsByPage, page);
          const progressMessage = `Viewed ${progressCount} of ${totalProducts} products`;

          return (
            <div
              aria-hidden={isLoading}
              data-testid={`product-page-${page}`}
              key={`product-page-${page}`}
            >
              <ProductListContainer
                expandedProducts={expandedProducts}
                favouriteProductCodes={favouriteProductCodes}
                page={page}
                products={products}
                {...(renderExpandedResultsMessage && {
                  expandedResultsMessage,
                })}
              />
              <div css={[styles.progressWrapper, styles.fadeIn]} id={PAGINATION_WRAPPER_ID}>
                {lastPageDisplayed === page ? (
                  <>
                    <p css={styles.progressMessage}>{progressMessage}</p>
                    <ProgressBar part={progressCount} total={totalProducts} />
                  </>
                ) : (
                  <Divider text={progressMessage} />
                )}
              </div>
            </div>
          );
        })
      )}
      {loadingNextPage && <SkeletonProductList />}
      {!isLoading && lastPageDisplayed < totalPages && (
        <PaginationButtonLink
          href={loadMoreURL}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onLoadMore({ page: lastPageDisplayed });
          }}
        >
          Load more<span css={visuallyHidden}> products</span>
        </PaginationButtonLink>
      )}
    </div>
  );
};
