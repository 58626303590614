import React from 'react';
import { SolidButtonLink } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './ProductPages.styles';

interface PaginationButtonLinkProps {
  children: React.ReactNode;
  href: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const PaginationButtonLink = ({ children, href, onClick }: PaginationButtonLinkProps) => {
  return (
    <div css={styles.loadPageWrapper}>
      <SolidButtonLink
        css={styles.loadPreviousButton}
        href={href}
        onClick={onClick}
        rel="nofollow"
        role="button"
      >
        {children}
      </SolidButtonLink>
    </div>
  );
};
